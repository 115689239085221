import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import { OTPUtils } from '../components/OneTimePurchase';
import { CheckoutChoosePurchaseOption } from '../components/OneTimePurchase/OTPCheckoutChoosePurchaseOption';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  return {
    headcount,
  };
}

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Book a Demo | Purchase ${pack.name}`));

  return (
    <CheckoutChoosePurchaseOption
      pack={pack}
      product={product}
      headcount={headcount}
      mode='enterprise-pricing'
    />
  );
}
